<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="container">
      <div class="row">
        <div class="col">
          <form>
            <div class="form-group">
              <label for="provider">Fornecedor</label>
              <v-select
                @input="updateInvoiceType()"
                :options="suppliers"
                v-model="supplier"
                :reduce="(supp) => supp.name"
                label="name"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}</template
                >
                <template slot="selected-option" slot-scope="option">
                  {{ option.name }}</template
                >
              </v-select>
              <b-button v-if="supp_url != null" @click="openSite()"
                >🌐</b-button
              >
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >NIF</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="supp_nif"
                disabled
              />
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Contacto</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="supp_contact"
                disabled
              />
            </div>
            <!-- <div class="form-group">
              <label for="invoiceNumber">Número da Fatura</label>
              <input type="text" class="form-control" id="invoiceNumber" v-model="invoiceId" />
            </div> -->
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Tipo Fatura</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="tipo_fatura"
                disabled
              />
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >IMG Receção</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="img_reception"
              />
            </div>
            <!-- <div class="form-group">
              <label for="date">Data</label>
              <input type="date" class="form-control" id="date" />
            </div> -->
          </form>
        </div>
        <div class="col">
          <div class="row">
            <div class="column">
              <div class="box box1">
                <img :src="img" />
              </div>
            </div>
            <div class="column">
              <form>
                <div class="form-group">
                  <label for="sku">SKU</label>
                  <input
                    type="text"
                    class="form-control"
                    id="sku"
                    v-model="skuInfo"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label for="ean">EAN</label>
                  <input
                    type="text"
                    class="form-control"
                    id="ean"
                    v-model="eanInfo"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label for="productName">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    id="productName"
                    disabled
                    v-model="nameInfo"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col" style="text-align: right"><b>Sem IVA</b></div>
        <div class="col-sm-1" style="text-align: center">
          <b-form-checkbox
            v-model="with_iva"
            name="check-button"
            switch
            size="lg"
          >
          </b-form-checkbox>
        </div>
        <div class="col" style="text-align: left"><b>Com IVA</b></div>
      </div>
    </div>
    <div style="text-align: center; font-size: 25px; margin-top: 10px">
      <p>
        <b
          >Fatura <span v-if="with_iva">Com IVA</span>
          <span v-else>Sem IVA</span></b
        >
      </p>
    </div>
    <form v-on:submit.prevent="getProduct()">
      <div id="searchEan" class="form-group row">
        <label for="productEan" id="lblProductEan" class="col-sm col-form-label"
          ><b>Cód. Produto:</b></label
        >
        <div class="col-sm">
          <input
            type="text"
            class="form-control"
            id="productEan"
            autofocus
            v-model="ean"
          />
        </div>
        <div class="col-sm">
          <button class="btn btn-primary">Adicionar</button>
        </div>
      </div>
    </form>
    <table class="table" id="newOrder">
      <thead>
        <tr>
          <th scope="col">SKU</th>
          <th scope="col" style="width: 35%">Nome</th>
          <th scope="col">Qtd.</th>
          <th scope="col" v-if="with_iva">Preço (c/IVA)</th>
          <th scope="col" v-else>Preço (s/IVA)</th>
          <th scope="col" style="width: 7%">IVA %</th>
          <th scope="col">Desc.</th>
          <th scope="col">Desc. %</th>
          <th scope="col" style="width: 3%">Validade</th>
          <th scope="col">Peso (g)</th>
          <th scope="col">Total</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, index) in newProducts"
          :key="product.id"
          :style="
            product.sku == skuInfo
              ? { 'background-color': '#ff9393' }
              : { 'background-color': 'white' }
          "
        >
          <td>{{ product.sku }}</td>
          <td>{{ product.name }}</td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="newProducts[index].qty_received"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].price"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].iva"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].discount"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].discount_per"
            />
          </td>
          <td>
            <date-picker
              v-model="newProducts[index].validity"
              type="month"
              format="YYYY-MM"
              value-type="format"
              :lang="lang"
            ></date-picker>
            <span id="infinity" @click="setDataInfinity(newProducts[index])">
              Não tem validade
            </span>
          </td>
          <td>
            <input
              class="form-control"
              type="number"
              v-model="newProducts[index].weight"
            />
          </td>
          <td
            v-if="
              newProducts[index].discount_per == '' ||
              newProducts[index].discount_per == undefined
            "
          >
            {{
              (newProducts[index].total_price =
                Number(newProducts[index].price) *
                  Number(newProducts[index].qty_received) -
                newProducts[index].discount)
            }}€
          </td>
          <td v-else>
            {{
              (newProducts[index].total_price =
                Number(newProducts[index].price) *
                Number(newProducts[index].qty_received) *
                (1 - newProducts[index].discount_per / 100)).toFixed(2)
            }}€
          </td>
          <td>
            <button
              type="button"
              class="btn btn-danger"
              @click="removeProduct(product.id)"
            >
              -
            </button>
          </td>
          <span style="display: none" v-if="with_iva">
            {{
              (newProducts[index].price_no_iva = (
                (Number(newProducts[index].price) -
                  Number(newProducts[index].discount)) /
                1.23
              ).toFixed(2))
            }}
          </span>
        </tr>
      </tbody>
    </table>
    <div>
      <h5>
        Desconto
        <input type="number" v-model="discount" max-length="8" />
      </h5>
      <h5>
        Valor Extra
        <input type="number" v-model="extraValue" max-length="8" />
      </h5>
      <h5 v-if="!with_iva">
        Total s/IVA:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return sum + Number(product.total_price);
              }, Number(-this.discount) + Number(this.extraValue))
              .toFixed(2)
          }}€
        </b>
        <br />
        Valor IVA:
        <b>
          {{
            (
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              ) *
                1.23 -
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              )
            ).toFixed(2)
          }}€
        </b>
        <br />
        Total c/IVA:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return (
                  sum +
                  Number(product.total_price) * (1 + Number(product.iva) / 100)
                );
              }, Number(-this.discount) + Number(this.extraValue))
              .toFixed(2)
          }}€
        </b>
      </h5>
      <h5 v-if="!farmas_bool && with_iva">
        Total s/IVA:
        <b>
          {{
            (
              this.newProducts.reduce((sum, product) => {
                return sum + Number(product.total_price);
              }, Number(-this.discount) + Number(this.extraValue)) / 1.23
            ).toFixed(2)
          }}€ </b
        ><br />
        Valor IVA:
        <b>
          {{
            (
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              ) -
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              ) /
                1.23
            ).toFixed(2)
          }}€ </b
        ><br />
        Total c/IVA:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return sum + Number(product.total_price);
              }, Number(-this.discount) + Number(this.extraValue))
              .toFixed(2)
          }}€
        </b>
      </h5>
    </div>
    <div class="buttons">
      <!-- <button
        type="submit"
        class="btn btn-primary"
        @click="addInvoice('processing')"
      >
        Guardar
      </button>
      <button
        type="submit"
        class="btn btn-warning"
        @click="addInvoice('invoice')"
      >
        Guardar s/ Fatura
      </button> -->
      <button type="submit" class="btn btn-success" @click="addInvoice()">
        Finalizar
      </button>
      <b-modal
        ref="modal_product"
        id="modal-3"
        title="Produto"
        size="lg"
        hide-footer
      >
        <div class="card">
          <b-container>
            <b-row align-v="center"
              ><b-col class="left">
                <!-- IMAGEM DO PRODUTO -->
                <img :src="imgProduct" />
              </b-col>
              <b-col>
                <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
                <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
                <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
                <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
                <p>
                  <span id="subtitle">Notas Arm.:</span>
                  {{ warehouseProduct }}
                </p>
                <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
                <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
              </b-col></b-row
            >
          </b-container>
          <button class="btn btn-info" @click="openProdPage()">
            Editar Produto
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      eans: [],
      ean: '',
      products: [],
      productInfo: [],
      skuInfo: '',
      eanInfo: '',
      nameInfo: '',
      img: 'https://www.svgrepo.com/show/97910/image.svg',
      newProducts: [],
      invoiceId: '',
      tipo_fatura: '',
      supp_url: '',
      supp_contact: '',
      supp_nif: '',
      supplier: '',
      error: false,
      suppliers: [],
      discount: 0,
      extraValue: 0,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          // dddd
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
        },
      },
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      with_iva: true,
      justification: '',
      valueInvoice: 0,
      val_6_arr: [],
      val_9_arr: [],
      img_reception: '',
      file: '',
      fileUrl: '',
      validity_justification: '',
    };
  },
  methods: {
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        '_blank'
      );
      this.$refs['modal_product'].hide();
    },
    async openSite() {
      window.open(this.supp_url, '_blank');
    },
    async getAllProducts() {
      /* this.loading = true; */
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
      } catch (err) {
        alert(err);
      } /* finally {
        this.loading = false;
      } */
    },
    async getEAN() {
      try {
        await this.$store.dispatch('fetchProductsEAN');
        this.eans = this.getProductsEAN;
        console.log(this.eans);
      } catch (err) {
        alert(err);
      }
    },
    /* OBTER PRODUTOS */
    async getProduct() {
      await this.$store.dispatch('searchProductsEanSku', {
        search: this.ean,
      });
      let ean = this.getProductsSkuEan;

      if (ean.length == 0) {
        await this.$store.dispatch('searchProductsEanSku', {
          search: this.ean,
        });

        ean = this.getProductsSkuEan;
      }

      //Adicionar verificação se o produto está presente no site
      //Quero que o utilizador saiba que o produto não está presente no site e que ele escolha se quer adicionar ou não
      if (ean.length == 0 || ean[0].id_idivia == null) {
        const result = await this.$swal({
          icon: 'warning',
          title: 'Produto não encontrado',
          text: 'O produto não está presente no site. Deseja adicioná-lo à lista?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sim, adicionar',
          cancelButtonText: 'Não, cancelar',
        });

        // Verificar a escolha do usuário
        if (!result.isConfirmed) {
          // O usuário escolheu não adicionar, então retornamos
          return;
        }
      }
      /* CASO ENCONTRE PRODUTO ATRAVÈS DO SKU OU EAN VAMOS ADICIONAR O PRODUTO À LISTA  */
      if (ean.length != 0) {
        /* OBTER DADOS DO PRODUTO INSERIDO */
        // this.productInfo = this.products.filter((product) => product.sku.includes(ean[0].sku[0]));
        this.productInfo = ean;
        /* INSERIR DADOS PARA ILUSTRAR O ÚLTIMO PRODUTO INSERIDO */
        this.nameInfo = this.productInfo[0].name;
        this.eanInfo = this.productInfo[0].ean[0];
        this.skuInfo = this.productInfo[0].sku[0];
        this.img = this.productInfo[0].img;
        let validity = '';
        let weight = '';
        let index = this.newProducts.findIndex((prod) =>
          this.productInfo[0].sku.includes(prod.sku)
        );
        if (index == -1) {
          console.log('Armazem:', this.productInfo[0].note_warehouse == 'null');
          // VERIFICAR SE O PRODUTO A ADICIONAR TEM NOTA DE ARMAZÉM
          if (
            this.productInfo[0].note_warehouse != null &&
            this.productInfo[0].note_warehouse != 'null' &&
            this.productInfo[0].note_warehouse != '' &&
            this.productInfo[0].note_warehouse != undefined
          ) {
            await this.$swal({
              title:
                'O produto com SKU:' +
                this.productInfo[0].sku[0] +
                ' tem a seguinte nota: \n' +
                this.productInfo[0].note_warehouse,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK!',
            });
          }
          if (this.productInfo[0].validity != null) {
            validity = this.productInfo[0].validity.substring(0, 7);
          }
          if (this.productInfo[0].weight != null) {
            weight = this.productInfo[0].weight;
          }
          let objProduc = {
            id_prod: this.productInfo[0].id_backoffice,
            ean: this.productInfo[0].ean[0],
            sku: this.productInfo[0].sku[0],
            name: this.productInfo[0].name,
            qty_received: 1,
            price: 0.0,
            iva: this.productInfo[0].tax_iva,
            price_no_iva: 0.0,
            discount: 0.0,
            discount_per: 0.0,
            validity: validity,
            total_price: 0.0,
            total_price_no_iva: 0.0,
            weight: weight,
          };
          this.newProducts.push(objProduc);
          this.newProducts.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.newProducts[index].qty_received++;
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: 'Não encontramos o Produto :(',
          showConfirmButton: false,
        });
      }
      this.ean = '';
    },
    async setDataInfinity(product) {
      console.log('Infinito');
      product.validity = '2050-01';
      console.log(product);
    },
    async updateInvoiceType() {
      const help = this.suppliers.find((item) => item.name == this.supplier);
      this.supp_url = help.url;
      this.tipo_fatura = help.invoice;
      this.supp_contact = help.contact;
      this.supp_nif = help.nif;
    },
    async removeProduct(id) {
      const index = this.newProducts.findIndex((obj) => obj.id == id);
      console.log(index);
      this.newProducts.splice(index, 1);
    },
    async addInvoice() {
      if (
        this.supplier == 'FARMACIA MARTINS' ||
        this.supplier == 'FARMACIA ALVIM' ||
        this.supplier == 'FARMACIA PERELHAL' ||
        this.supplier == 'FARMACIA GOMES'
      ) {
        //Verificar se os produtos recebidos estão no ficheiro e se tem de aceitar o novo preço
        //Para o pedido preciso de um array com o id_backoffice e o novo preço
        let products = [];
        for (const prod of this.newProducts) {
          if (prod.price != 0 && prod.price != null) {
            let obj = {
              id_backoffice: prod.id_prod,
              price: prod.price,
            };
            products.push(obj);
          }
        }

        if (products.length != 0) {
          await this.$store.dispatch('getProductsPCustoInfo', {
            prods: products,
          });

          const productsInfo = this.getProductsPcusto;

          if (productsInfo.length != 0) {
            let text = `
          <div style="max-height: 400px; overflow-y: auto; text-align: left;">
            <p style="font-size: 18px; font-weight: bold; text-align: center; margin-bottom: 10px;">
              Produtos com preço diferente
            </p>
        `;

            for (const prod of productsInfo) {
              text += `
              <div style="margin-bottom: 10px; padding: 10px; border-bottom: 1px solid #ccc;">
                <p style="margin: 0;">
                  <strong>Produto:</strong> ${prod.name}
                </p>
                <p style="margin: 0;">
                  <strong>Preço recebido:</strong> ${prod.price}€
                </p>
                <p style="margin: 0;">
                  <strong>Preço de custo correto:</strong> ${prod.p_custo}€
                </p>
              </div>
            `;
            }

            text += `</div>`;

            const result = await this.$swal({
              title: 'Atenção!',
              html: text,
              icon: 'warning',
              width: '800px', // Aumenta a largura do SweetAlert
              showCancelButton: true,
              confirmButtonText: 'Aceitar',
              cancelButtonText: 'Cancelar',
            });

            if (!result.isConfirmed) {
              // Se o utilizador cancelar, parar aqui
              return;
            }
          }
        }
      }

      if (
        this.supplier != 'FARMACIA MARTINS (MARTINS)' &&
        this.supplier != 'FARMACIA ALVIM (ALVIM)' &&
        this.supplier != 'FARMACIA PERELHAL (PERELHAL)' &&
        this.supplier != 'FARMACIA GOMES (GOMES)' &&
        (this.img_reception == '' ||
          this.img_reception == null ||
          this.img_reception == undefined)
      ) {
        this.$swal({
          icon: 'error',
          title: 'Imagem de receção não inserida',
        });
        return;
      }

      let advance = true;
      let status = 'completed';
      let sem_data = false;
      let sem_peso = false;
      let sem_iva = false;
      this.total = this.newProducts.reduce((sum, product) => {
        return sum + Number(product.total_price);
      }, Number(-this.discount) + Number(this.extraValue));

      if (this.with_iva) {
        this.totalWithoutIVA = (
          this.newProducts.reduce((sum, product) => {
            return sum + Number(product.total_price);
          }, Number(-this.discount) + Number(this.extraValue)) / 1.23
        ).toFixed(2);
      } else {
        this.totalWithoutIVA = this.newProducts.reduce((sum, product) => {
          return sum + Number(product.total_price);
        }, Number(-this.discount) + Number(this.extraValue));
      }

      const date = new Date();
      date.setMonth(date.getMonth() + 6);
      const val_6 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      date.setMonth(date.getMonth() + 12);
      const val_9 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      this.val_6_arr = [];
      this.val_9_arr = [];

      for (const prod of this.newProducts) {
        if (
          prod.validity == null ||
          prod.validity == undefined ||
          prod.validity == ''
        )
          sem_data = true;
        if (
          prod.weight == null ||
          prod.weight == undefined ||
          prod.weight == ''
        )
          sem_peso = true;
        if (prod.iva == null || prod.iva == undefined || prod.iva == '')
          sem_iva = true;
        const val_date = new Date(`${prod.validity}-01`);

        if (val_6 > val_date) {
          this.val_6_arr.push(prod);
        } else if (val_6 < val_date && val_9 > val_date) {
          this.val_9_arr.push(prod);
        }
      }

      if (this.newProducts.length == 0) {
        this.$swal({
          icon: 'error',
          title: 'Sem produtos para recepção',
        });
        return;
      } else if (sem_data == true) {
        this.$swal({
          icon: 'error',
          title: 'Produto sem validade',
        });
        return;
      } else if (sem_peso == true) {
        this.$swal({
          icon: 'error',
          title: 'Produto sem peso',
        });
        return;
      } else if (sem_iva == true) {
        this.$swal({
          icon: 'error',
          title: 'Produto sem iva',
        });
        return;
      }

      // Se existirem produtos com validade baixa, solicitar a justificação
      if (this.val_6_arr.length != 0 || this.val_9_arr.length != 0) {
        let text = `<p style='color:red;'>Menos de 6 meses</p>`;
        for (const prod of this.val_6_arr) {
          text += `<p>${prod.sku} <> ${prod.validity}</p>`;
        }
        text += `<p style='color:blue;'>Menos de 12 meses</p>`;
        for (const prod of this.val_9_arr) {
          text += `<p>${prod.sku} <> ${prod.validity}</p>`;
        }

        await this.$swal({
          title:
            '<span style="color: red;">Existem produtos com validades baixas!</span>',
          icon: 'warning',
          html: text,
          showCancelButton: true,
          confirmButtonText: 'Justificar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.$swal({
              title: 'Justificação para as validades baixas',
              html: `<input type="text" id="validity_justification" class="form-control" placeholder="Justificação">`,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
              preConfirm: () => {
                const justification = this.$swal
                  .getPopup()
                  .querySelector('#validity_justification').value;
                if (!justification) {
                  this.$swal.showValidationMessage(
                    'Por favor, forneça uma justificação'
                  );
                }
                return justification;
              },
            }).then((result2) => {
              if (result2.isConfirmed) {
                this.validity_justification = result2.value;
                advance = true;
              } else {
                advance = false;
              }
            });
          } else {
            advance = false;
          }
        });
      }

      if (advance) {
        await this.$swal({
          title: 'Já tem a fatura impressa na sua mão?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, tenho!',
          cancelButtonText: 'Não, não tenho!',
        }).then(async (result) => {
          if (result.isConfirmed) {
            let html;
            if (this.invoiceId == '') {
              html = `<input type="text" id="numberInvoice" class="form-control" v-model="teste" placeholder="Nº da Fatura">
          <input type="text" id="invoiceTotal" class="form-control" v-model="teste1" placeholder="Valor Faturado">
          <label>Inserir Fatura</label>
          <input type="file" id="invoiceFile" class="form-control">`;
            } else {
              html = `<input type="text" id="invoiceTotal" class="form-control" v-model="teste1" placeholder="Valor Faturado">`;
            }

            await this.$swal({
              title: 'Dados da Fatura',
              html: html,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              focusConfirm: false,
              preConfirm: () => {
                if (this.invoiceId == '') {
                  const nInvoice = this.$swal
                    .getPopup()
                    .querySelector('#numberInvoice').value;
                  const invoiceTotal = this.$swal
                    .getPopup()
                    .querySelector('#invoiceTotal').value;
                  const invoiceFile = this.$swal
                    .getPopup()
                    .querySelector('#invoiceFile').files[0];
                  // const invoiceUrl = this.$swal
                  //   .getPopup()
                  //   .querySelector('#invoiceUrl').value;
                  if (
                    !nInvoice ||
                    !invoiceTotal ||
                    !invoiceFile
                    // (!invoiceFile && !invoiceUrl)
                  ) {
                    this.$swal.showValidationMessage(
                      `Por favor preencha os campos corretamente`
                    );
                  }
                  return {
                    nInvoice: nInvoice,
                    invoiceTotal: invoiceTotal,
                    invoiceFile: invoiceFile,
                    // invoiceUrl: invoiceUrl,
                  };
                } else {
                  const invoiceTotal = this.$swal
                    .getPopup()
                    .querySelector('#invoiceTotal').value;
                  if (!invoiceTotal) {
                    this.$swal.showValidationMessage(
                      `Por favor preencha os campos corretamente`
                    );
                  }
                  return { invoiceTotal: invoiceTotal };
                }
              },
            }).then(async (result2) => {
              if (result2.isConfirmed) {
                if (this.invoiceId == '') {
                  this.invoiceId = result2.value.nInvoice;
                  this.file = result2.value.invoiceFile;
                  // this.fileUrl = result2.value.invoiceUrl;
                }
                this.valueInvoice = result2.value.invoiceTotal;

                if (
                  result2.value.invoiceTotal > Number(this.total) + 1 ||
                  result2.value.invoiceTotal < Number(this.total) - 1
                ) {
                  await this.$swal({
                    title: 'Valor recepcionado diferente do recebido!',
                    text: 'Quer validar mesmos assim, ou corrigir?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Validar!',
                    cancelButtonText: 'Corrigir',
                  }).then(async (result3) => {
                    if (result3.isConfirmed) {
                      await this.$swal({
                        title: 'Justificação Motivo da Diferença',
                        html: `<input type="text" id="justification" class="form-control" placeholder="Justificação">`,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        focusConfirm: false,
                        preConfirm: () => {
                          const justification = this.$swal
                            .getPopup()
                            .querySelector('#justification').value;
                          if (!justification) {
                            this.$swal.showValidationMessage(
                              `Por favor preencha os campos corretamente`
                            );
                          }
                          return { justification: justification };
                        },
                      }).then((result4) => {
                        this.justification = result4.value.justification;
                        if (result4.isConfirmed) {
                          advance = true;
                          status = 'completed';
                        } else {
                          advance = false;
                        }
                      });
                    } else {
                      advance = false;
                    }
                  });
                }
              } else {
                advance = false;
              }
            });
          } else {
            advance = true;
            status = 'completed';
          }
        });
      }

      if (advance) {
        try {
          let supp_id;
          if (
            this.supplier == null ||
            this.supplier == undefined ||
            this.supplier == ''
          ) {
            this.supplier = '';
          } else {
            const id_supp = this.suppliers.filter(
              (supp) => supp.name == this.supplier
            );
            supp_id = id_supp[0].id;
          }

          await this.$store.dispatch('fetchInvoiceSuppliers', {
            invoiceId: this.invoiceId,
            products: this.newProducts,
            date: this.date,
            supplier: supp_id,
            status: status,
            total: this.total,
            totalWithoutIVA: this.totalWithoutIVA,
            discount: this.discount,
            extraValue: this.extraValue,
            valueInvoice: Number(this.valueInvoice),
            justification: this.justification,
            withIva: this.with_iva,
            validity_justification: this.validity_justification,
          });

          await this.addImgReception(this.getIdInvoice);
          if (this.invoiceId != '') {
            let file = this.file;
            // if (!file) {
            //   file = this.fileUrl;
            // }
            await this.addFileReception(
              file,
              this.getIdInvoice,
              this.invoiceId
            );
          }
          this.$swal(this.getMessage, '', 'success');
          this.$router.go();
        } catch (err) {
          this.error = true;
          alert(err);
        }
      }
    },
    async addImgReception(id) {
      try {
        await this.$store.dispatch('addImageProductsReceived', {
          id: id,
          image: this.img_reception,
        });
        this.$swal('Imagem adicionada com sucesso', '', 'success');
      } catch (err) {
        alert(err);
      }
    },
    async addFileReception(file, id, invoice_id) {
      try {
        await this.$store.dispatch('addFileReceptions', {
          id: id,
          file: file,
          invoice_id: invoice_id,
        });
        this.$swal('Ficheiro adicionado com sucesso', '', 'success');
      } catch (err) {
        alert(err);
      }
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch('getAllSuppliers');
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: `${element.name} (${element.other_name})`,
            invoice: element.invoice_type,
            contact: element.email,
            nif: element.nif,
            url: element.url,
          };
          this.suppliers.push(obj);
        }
      } catch (error) {
        alert(error);
      }
    },
    async passProductData(data) {
      console.log(data);
      // let filterProduct = this.products.filter((product) => product.sku.includes(data.sku));
      await this.$store.dispatch('searchProductsEanSku', {
        search: data.sku,
      });
      let filterProduct = this.getProductsSkuEan;

      if (filterProduct.length == 0) {
        await this.$store.dispatch('searchProductsEanSku', {
          search: data.sku,
        });

        filterProduct = this.getProductsSkuEan;
      }
      window.open(
        `https://${window.location.host}/product/${filterProduct[0].id_backoffice}`,
        '_blank'
      );
      console.log(filterProduct);
      this.eanProduct = filterProduct[0].ean;
      this.skuProduct = filterProduct[0].sku;
      this.hsProduct = filterProduct[0].hscode;
      this.warehouseProduct = filterProduct[0].note_warehouse;
      this.notesProduct = filterProduct[0].note_finish;
      this.weightProduct = filterProduct[0].weight;
      this.imgProduct = filterProduct[0].img;
      this.idProduct = filterProduct[0].id_backoffice;
      this.stockProduct = filterProduct[0].stock;
      console.log(this.eanProduct);
      console.log(this.skuProduct);
      console.log(filterProduct, data);
    },
  },
  async created() {
    // this.getEAN();
    this.loading = true;
    // await this.getAllProducts();
    await this.getAllSuppliers();
    this.loading = false;
  },
  computed: {
    // ...mapGetters(['getProductsEAN']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getMessage']),
    ...mapGetters(['getSuppliers']),
    ...mapGetters(['getIdInvoice']),
    ...mapGetters(['getProductsSkuEan']),
    ...mapGetters(['getProductsPcusto']),
  },
};
</script>

<style scoped>
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.box1 {
  border: 3px solid #ff9393;
  border-radius: 5px;
}
.column {
  float: left;
  width: 50%;
  padding: 5px;
}
#searchEan {
  margin-top: 50px;
}
#lblProductEan {
  text-align: right;
}
/* table {
  table-layout: fixed;
} */
h5 {
  text-align: right;
  margin-right: 7%;
}
.buttons {
  text-align: right;
}
.buttons button {
  margin: 5px;
}
#infinity:hover {
  cursor: pointer;
  color: aqua;
}
</style>
